import { Link } from "@remix-run/react";
import { ArrowRight, PlusIcon } from "lucide-react";
import React from "react";
import { erc20Abi } from "viem";
import { useAccount, useReadContracts } from "wagmi";
import * as toast from "~/components/toast";
import * as config from "~/config";
import { useCurrentUser } from "~/hooks/use-current-user";
import { useSupportedChains } from "~/hooks/use-supported-chains";
import { SwitchChain } from "~/routes/_app.sticker.$id";
import { Button } from "./button";

export function Wallet() {
  const [currentUser] = useCurrentUser();
  const { address, chainId, isConnected } = useAccount();
  const { supportedChains } = useSupportedChains();
  const isChainSupported = supportedChains.some(
    (supportedChain) => supportedChain.chain_id === chainId,
  );
  const chain = isChainSupported ? (chainId ?? 84532) : 84532;

  const {
    popTokenAddress: tokenAddress,
    buyLink,
    sellLink,
  } = React.useMemo(() => config.chainAddresses[chain], [chain]);
  const handleCopyLink = () => {
    navigator.clipboard.writeText(address || currentUser?.username || "");
    toast.ok({ text: "Copied to clipboard" });
  };
  const balance = useReadContracts({
    contracts: [
      {
        abi: erc20Abi,
        functionName: "balanceOf",
        address: tokenAddress,
        args: [address],
      },
      {
        abi: erc20Abi,
        functionName: "symbol",
        address: tokenAddress,
      },
    ],
  });

  return (
    <>
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2 flex-1 w-7/12">
          <img
            src={currentUser?.avatarUrl || "/default-avatar.svg"}
            alt="avatar"
            className="w-8 h-8 rounded-full object-cover border border-[#B999FF]"
          />
          <p className="text-sm font-medium overflow-hidden text-ellipsis max-w-32">
            {isConnected ? address : currentUser?.username}
          </p>
          <button onClick={handleCopyLink} type="button" className="w-8 h-8">
            <img src="/icons/content_copy.svg" alt="copy" />
          </button>
        </div>
        <Link to="/wallets">
          <Button className="py-2 px-4 border border-[#B999FF] bg-transparent bg-none hover:bg-none hover:bg-[#BCBEFF]">
            {address ? "Change wallet" : "Connect Wallet"}
          </Button>
        </Link>
      </div>
      {isConnected ? (
        <div className="rounded-lg bg-[linear-gradient(180deg,#DCAFFF_0%,#BFDDFF_100%)] p-4">
          <div className="flex gap-2 justify-between items-center">
            <div className="flex gap-1 overflow-hidden">
              <img src="/icons/eth.svg" className="w-3" alt="currency icon" />
              <div className="font-medium text-xl overflow-hidden text-ellipsis">
                {balance.data?.[0].result
                  ? Number.parseInt(balance.data?.[0].result?.toString()) /
                    10 ** 18
                  : 0}{" "}
              </div>
              <div className="font-medium text-xl">
                {balance.data?.[1].result}
              </div>
              {/* // TODO: add price after token launch */}
              {/* <p className="text-sm">$0.00 USD</p> */}
            </div>
            <div className="flex gap-1">
              {isChainSupported ? (
                <>
                  {buyLink ? (
                    <Link to={buyLink}>
                      <Button className="rounded-full border border-solid border-indigo-400 bg-gradient-to-b from-blue-100 to-green-100">
                        <PlusIcon size={20} color="#B999FF" /> Buy
                      </Button>
                    </Link>
                  ) : null}
                  {sellLink ? (
                    <Link to={sellLink}>
                      <Button className="rounded-full border border-solid border-indigo-400 bg-gradient-to-b from-blue-100 to-green-100">
                        <ArrowRight size={20} color="#B999FF" />
                        Sell
                      </Button>
                    </Link>
                  ) : null}
                </>
              ) : (
                <SwitchChain chains={supportedChains} />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
